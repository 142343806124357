export default function () {
    let nav = document.querySelector('.header__nav');
    let burger = document.querySelector('.header__burger');
    let search = document.querySelector('.header__search');
    let html = document.querySelector('html');
    let body = document.querySelector('body');
    let logo = document.querySelector('.header__logo');
    
    burger.addEventListener('click', () => {
    logo.classList.toggle('active');
    nav.classList.toggle('open');
    burger.classList.toggle('close');
    search.classList.toggle('close');
    html.classList.toggle('no-scroll');
    body.classList.toggle('no-scroll');
    if (burger.classList.contains('close')) {
        burger.style.transform = 'rotate(90deg)';
    } else {
        burger.style.transform = 'rotate(0deg)';
    }
    if (nav.classList.contains('open')) {
        nav.style.transform = 'translateX(0%)';
    } else {
        nav.style.transform = 'translateX(-150%)';
    }
    });
}
