export default function () {
    let copyButtons = $('.single-post__copy');
    if(copyButtons.length) {   
        copyButtons.on('click', function(event) {
            event.preventDefault();
            let button = $(this);
            let url = button.data('url');
            let tooltip = button.find('.tooltip');
            
            function showTooltip() {
                tooltip.text('Link copiado!');
                tooltip.css({
                    'visibility': 'visible',
                    'opacity': 1
                });
                setTimeout(() => {
                    tooltip.css({
                        'visibility': 'hidden',
                        'opacity': 0
                    });
                }, 2000);
            }
        
            function copyTextToClipboard(text) {
                let textArea = $('<textarea>').val(text).appendTo('body');
                textArea.select();
                try {
                    document.execCommand('copy');
                    showTooltip();
                } catch (err) {
                    console.error('Failed to copy text: ', err);
                }
                textArea.remove();
            }
            
            copyTextToClipboard(url);
        });
    }

    if ($('.scrollable-section').length) {
        let scrollableSection = $('.scrollable-section');
        let mainCoursesSection = $('.main-courses');
        let coursesList = mainCoursesSection.find('.courses__list');
        let lastElement = scrollableSection.children().last();
        
        let sectionWidth = scrollableSection.innerWidth();
        let lastElementWidth = lastElement.outerWidth(true);
        
        let marginRight = parseFloat(scrollableSection.css('margin-right'));
        
        if (marginRight < 0) {
            marginRight = Math.abs(marginRight);
        }
        
        let spaceToAdd = sectionWidth - lastElementWidth;
        if (spaceToAdd < 0) spaceToAdd = 0;
        
        if (spaceToAdd + marginRight <= 0) {
            return;
        }
        
        let spacerWidth = spaceToAdd + marginRight;
        
        let spacer = $('<div></div>').css({
            'width': spacerWidth + 'px',
            'height': '1px',
            'display': 'inline-block'
        });
        
        coursesList.append(spacer);
        
        let disabledClass = 'scroll-disabled';
        
        scrollableSection.on('wheel', function(event) {
            if (event.originalEvent.deltaX) {
                $(this).scrollLeft($(this).scrollLeft() + event.originalEvent.deltaX);
                $(this).addClass(disabledClass); 
                event.preventDefault();
            }
        });
        
        let isDragging = false;
        let startX;
        let scrollLeft;
        
        scrollableSection.on('mousedown', function(e) {
            isDragging = true;
            startX = e.pageX - $(this).offset().left;
            scrollLeft = $(this).scrollLeft();
        });
        
        $(window).on('mousemove', function(e) {
            if (isDragging) {
                let x = e.pageX - scrollableSection.offset().left;
                let walk = (x - startX) * 1;
                scrollableSection.scrollLeft(scrollLeft - walk);
                scrollableSection.addClass(disabledClass);
            }
        });
        
        $(window).on('mouseup', function() {
            isDragging = false;
            scrollableSection.removeClass(disabledClass); 
        });
        
        scrollableSection.on('scroll', function() {
            if (scrollableSection.hasClass(disabledClass)) {
                scrollableSection.removeClass(disabledClass);
            }
        });
    }
    
}
