export default function () {
    const sidebarLinks = document.querySelectorAll('.faq__categories-link');
    const sections = Array.from(document.querySelectorAll('.faq__title, .faq__item'));
    function updateActiveLink() {
        let currentIndex = -1;
        let closestDistance = Infinity;

        sections.forEach((section, index) => {
            const rect = section.getBoundingClientRect();
            const distanceToTop = Math.abs(rect.top);
            if (rect.top < window.innerHeight / 2 && rect.bottom > 0) {
                if (distanceToTop < closestDistance) {
                    closestDistance = distanceToTop;
                    currentIndex = index;
                }
            }
        });
        sidebarLinks.forEach(link => link.classList.remove('faq__categories-link--active'));
        if (currentIndex !== -1) {
            const id = sections[currentIndex].id;
            const activeLink = document.querySelector(`.faq__categories-link[href="#${id}"]`);
            if (activeLink) {
                activeLink.classList.add('faq__categories-link--active');
            }
        }
    }
    window.addEventListener('scroll', updateActiveLink);
    function activateLink(event) {
        event.preventDefault();
        sidebarLinks.forEach(link => link.classList.remove('faq__categories-link--active'));
        event.target.classList.add('faq__categories-link--active');

        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
    sidebarLinks.forEach(link => link.addEventListener('click', activateLink));
    updateActiveLink();
}
