export default function () {
    let button = $('#load-more');

    function load_posts() {
        let page = button.data('page') + 1;
        let ajaxurl = button.data('url');
        let category = button.data('category');

        $.ajax({
            url: ajaxurl,
            type: 'POST',
            dataType: 'json',
            data: {
                page: page,
                action: 'load_more_posts',
                category: category
            },
            beforeSend: function() {
                button.text('Carregando...');
            },
            success: function(response) {
                if (response.html) {
                    $('#archive-posts').append(response.html);
                    button.data('page', page);
                    button.text('Carregar mais');

                    if (!response.more_posts) {
                        button.hide();
                    }
                } else {
                    button.hide();
                }
            }
        });
    }

    button.on('click', function() {
        load_posts();
    });
    
}
