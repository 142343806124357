export default async function () {
    if (!document.querySelector('.archive-top__wrapper')) {
        return;
    }    
    const filters = document.querySelectorAll('.filter__checkbox input, .filter__input');
    const clearButton = document.getElementById('clear-filters');
    const courseList = document.querySelector('.archive-courses__list');
    const paginationWrapper = document.querySelector('.pagination');
    const loader = document.getElementById('loader');

    const categorySlug = document.querySelector('.archive-top__wrapper').dataset.categorySlug;

    let typingTimer;
    const typingDelay = 1000;

    function showLoader() {
        loader.style.display = 'block';
    }

    function hideLoader() {
        loader.style.display = 'none';
    }

    function renderPagination(currentPage, totalPages) {
        let paginationHTML = '';
    
        const isMobile = window.innerWidth <= 1024;
    
        if (isMobile) {
            if (totalPages > 1) {
                if (currentPage > 1) {
                    paginationHTML += `<li class="pagination__item"><a href="#" class="pagination__link pagination__prev" data-page="${currentPage - 1}"></a></li>`;
                }
                paginationHTML += `<li class="pagination__item ${currentPage === 1 ? 'pagination__item--active' : ''}">
                                    <a href="#" class="pagination__link" data-page="1">1</a>
                                </li>`;
    
                if (currentPage > 2) {
                    paginationHTML += `<li class="pagination__item"><span class="pagination__link">...</span></li>`;
                }
    
                if (currentPage > 1 && currentPage < totalPages) {
                    paginationHTML += `<li class="pagination__item pagination__item--active">
                                        <a href="#" class="pagination__link" data-page="${currentPage}">${currentPage}</a>
                                    </li>`;
                }
    
                if (currentPage < totalPages - 1) {
                    paginationHTML += `<li class="pagination__item"><span class="pagination__link">...</span></li>`;
                }
    
                paginationHTML += `<li class="pagination__item ${currentPage === totalPages ? 'pagination__item--active' : ''}">
                                    <a href="#" class="pagination__link" data-page="${totalPages}">${totalPages}</a>
                                </li>`;
                if (currentPage < totalPages) {
                    paginationHTML += `<li class="pagination__item"><a href="#" class="pagination__link pagination__next" data-page="${currentPage + 1}"></a></li>`;
                }
            }
        } else {
            if (totalPages > 1) {
                if (currentPage > 1) {
                    paginationHTML += `<li class="pagination__item"><a href="#" class="pagination__link pagination__prev" data-page="${currentPage - 1}">Anterior</a></li>`;
                }
    
                paginationHTML += `<li class="pagination__item ${currentPage === 1 ? 'pagination__item--active' : ''}">
                                    <a href="#" class="pagination__link" data-page="1">1</a>
                                </li>`;
    
                if (currentPage > 4) {
                    paginationHTML += `<li class="pagination__item"><span class="pagination__link">...</span></li>`;
                }
    
                for (let i = Math.max(2, currentPage - 2); i <= Math.min(currentPage + 2, totalPages - 1); i++) {
                    paginationHTML += `<li class="pagination__item ${i === currentPage ? 'pagination__item--active' : ''}">
                                        <a href="#" class="pagination__link" data-page="${i}">${i}</a>
                                    </li>`;
                }
    
                if (currentPage < totalPages - 3) {
                    paginationHTML += `<li class="pagination__item"><span class="pagination__link">...</span></li>`;
                }
    
                paginationHTML += `<li class="pagination__item ${currentPage === totalPages ? 'pagination__item--active' : ''}">
                                    <a href="#" class="pagination__link" data-page="${totalPages}">${totalPages}</a>
                                </li>`;
    
                if (currentPage < totalPages) {
                    paginationHTML += `<li class="pagination__item"><a href="#" class="pagination__link pagination__next" data-page="${currentPage + 1}">Próximo</a></li>`;
                }
            }
        }
    
        paginationWrapper.innerHTML = paginationHTML;
        paginationWrapper.style.display = 'flex';
    
        const paginationLinks = document.querySelectorAll('.pagination__link');
        paginationLinks.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                const page = parseInt(e.target.getAttribute('data-page'), 10);
                fetchFilteredCourses(page);
            });
        });
    }
    


    async function fetchFilteredCourses(page = 1) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        showLoader();
    
        let filterData = { page };
        if (categorySlug) {
            filterData['category_slug'] = categorySlug;
        }
        filters.forEach(filter => {
            if ((filter.type === "checkbox" && filter.checked) || 
                (filter.type === "text" && filter.value.trim() !== "")) {
                filterData[filter.id] = filter.type === "checkbox" ? filter.checked : filter.value;
            }
        });
    
        const params = new URLSearchParams(filterData).toString();
        try {
            const response = await fetch(`/wp-json/custom-api/filter-courses?${params}`);
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Error fetching courses');
            }
    
            hideLoader();
            courseList.innerHTML = data.html;
            
            if (!data.total_courses || data.total_courses === 0) {
                courseList.innerHTML = '<h1 style="text-align: center;">Cursos não encontrados :(</h1>';
                paginationWrapper.style.display = 'none';
                paginationWrapper.innerHTML = '';
            } else if (data.total_courses > 0 && data.total_courses <= 8) {
                paginationWrapper.style.display = 'none';
                paginationWrapper.innerHTML = '';
            } else {
                renderPagination(page, data.total_pages);
            }

            history.pushState(null, '', `?${params}`);
            try {
                if (typeof window.adsbygoogle !== 'undefined') {
                    let ads = document.querySelectorAll('.ad-insert .adsbygoogle');
                    
                    ads.forEach(ad => {
                        if (!ad.hasAttribute('data-adsbygoogle-status')) {
                            (adsbygoogle = window.adsbygoogle || []).push({});
                            
                            setTimeout(() => {
                                if (!ad.hasAttribute('data-adsbygoogle-status')) {
                                    ad.closest('.ad-insert').remove(); 
                                }
                            }, 1000);
                        } 
                    });
                } 
            } catch (adsError) {
            }
        } catch (error) {
            hideLoader();
            courseList.innerHTML = '<h1 style="text-align: center;">Cursos não encontrados :(</h1>';
            paginationWrapper.style.display = 'none';
            paginationWrapper.innerHTML = '';
        }
    }

    filters.forEach(filter => {
        if (filter.type === 'text') {
            filter.addEventListener('input', () => {
                clearTimeout(typingTimer); 
                typingTimer = setTimeout(() => fetchFilteredCourses(1), typingDelay);
            });
        } else {
            filter.addEventListener('change', () => fetchFilteredCourses(1));
        }
    });

    clearButton.addEventListener('click', function () {
        filters.forEach(filter => {
            if (filter.type === 'checkbox') {
                filter.checked = false;
            } else if (filter.type === 'text') {
                filter.value = '';
            }
        });
        const rootUrl = window.location.origin + '/pt-br/cursos/';
        window.location.replace(rootUrl);
    });

}
