export default function () {
    let $ratingValue = $('.rating-value');
    if ($ratingValue.length) {
        let ratingValue = parseFloat($ratingValue.text());
        let $stars = $('.star');
        let starCount = $stars.length;
        
        $stars.each(function(index) {
            let $star = $(this);
            let $starFill = $star.find('.mask');
            let fullStarWidth = $star.outerWidth();
            
            if (ratingValue > index) {
                if (ratingValue > index + 1) {
                    $starFill.css('clip-path', 'none');
                } else {
                    let fillWidth = (ratingValue - index) * fullStarWidth;
                    $starFill.css('clip-path', `inset(0 ${fullStarWidth - fillWidth}px 0 0)`);
                }
            } else {
                $starFill.css('fill', 'none');
            }
        });
    }
    let sidebar = document.querySelector('.sidebar-nav');
    if (sidebar) {
        let navItems = sidebar.querySelectorAll('li a');
        let sections = Array.from(navItems).map(item => document.querySelector(item.getAttribute('href')));
        let isScrolling;

        function removeActiveClasses() {
            navItems.forEach(item => item.parentElement.classList.remove('active'));
        }

        function addActiveClass(index) {
            navItems[index].parentElement.classList.add('active');
        }

        function updateActiveClass() {
            let scrollY = window.scrollY;
            let currentIndex = -1;
    
            sections.forEach((section, index) => {
                if (section) {
                    let rect = section.getBoundingClientRect();
                    let sectionTop = rect.top + scrollY;
                    let sectionBottom = sectionTop + section.offsetHeight;
    
                    let expandedSectionTop = sectionTop - 50; 
                    let expandedSectionBottom = sectionBottom + 50;
    
                    if (scrollY >= expandedSectionTop && scrollY < expandedSectionBottom) {
                        currentIndex = index;
                    }
                }
            });
    
            if (currentIndex !== -1) {
                removeActiveClasses();
                addActiveClass(currentIndex);
            }
        }
        navItems.forEach((item, index) => {
            item.addEventListener('click', function() {
                removeActiveClasses();
                addActiveClass(index);
    
                let targetSection = document.querySelector(item.getAttribute('href'));
                if (targetSection) {
                    window.scrollTo({
                        top: targetSection.getBoundingClientRect().top + window.scrollY - 50,
                        behavior: 'smooth'
                    });
                }
    
                clearTimeout(isScrolling);
                isScrolling = setTimeout(updateActiveClass, 100);
            });
        });
        window.addEventListener('scroll', function() {
            clearTimeout(isScrolling);
            isScrolling = setTimeout(updateActiveClass, 100);
        });
    }       
}
